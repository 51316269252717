import { ArticleSchemaParams } from '~/modules/jsonLD/composables/useJsonLd/useJsonLd';
import { useRoute } from '@nuxtjs/composition-api';

const articleSchema = ({
  headline, image, author, datePublished,
}: ArticleSchemaParams) => {
  const route = useRoute();

  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: headline ?? '',
    image: image ?? '',
    datePublished: datePublished ?? '',
    url: `https://agrosimex.pl${route.value.path}`,
    author: {
      '@type': 'Person',
      name: author ?? 'Agrosimex',
    },
  };
};

export default articleSchema;
