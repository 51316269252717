import { render, staticRenderFns } from "./ImageWrapper.vue?vue&type=template&id=27a0d045&scoped=true&"
import script from "./ImageWrapper.vue?vue&type=script&lang=ts&"
export * from "./ImageWrapper.vue?vue&type=script&lang=ts&"
import style0 from "./ImageWrapper.vue?vue&type=style&index=0&id=27a0d045&prod&lang=scss&scoped=true&"
import style1 from "./ImageWrapper.vue?vue&type=style&index=1&id=27a0d045&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a0d045",
  null
  
)

export default component.exports