import { ProductSchemaParams } from '~/modules/jsonLD/composables/useJsonLd/useJsonLd';
import { cleanHtmlDescription, productPriceForSchemaOffer } from '~/modules/jsonLD/helpers';
import { AvailabilityEnum } from '~/modules/jsonLD/types';
import { useImage } from '~/composables';
import { useRoute } from '@nuxtjs/composition-api';
import hasMerchantReturnPolicy from '~/modules/jsonLD/schemes/product/hasMerchantReturnPolicy';

const simpleSchema = ({
  title, product, sortedProductGallery, imageSizes, manufacturerLabel, finalPrice, available, productEan, deliveryTimeLabel,
}:ProductSchemaParams) => {
  const { getMagentoImage } = useImage();
  const route = useRoute();

  const offer = {
    price: finalPrice.value,
    availability: available.value.stock_status,
    url: route.value.fullPath,
    ean: productEan.value,
  };

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: title.value,
    description: cleanHtmlDescription(product.value?.description?.html) || '',
    sku: product.value.sku,
    image: getMagentoImage(
      sortedProductGallery.value[0]?.desktop?.url,
      {
        width: imageSizes.ogImage.width,
        height: imageSizes.ogImage.height,
      },
    ) || '',
    ...(manufacturerLabel.value && {
      brand: {
        '@type': 'Brand',
        name: manufacturerLabel.value,
      },
    }),
    offers: {
      '@type': 'Offer',
      priceCurrency: 'PLN',
      price: productPriceForSchemaOffer({
        sku: product.value.sku,
        offer,
      }),
      availability: `http://schema.org/${AvailabilityEnum[offer.availability]}`,
      url: `https://agrosimex.pl${offer.url}`,
      ...(offer.ean && { gtin13: offer.ean }),
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingRate: {
          '@type': 'MonetaryAmount',
          value: 0,
          currency: 'PLN',
        },
        shippingDestination: {
          '@type': 'DefinedRegion',
          addressCountry: {
            '@type': 'Country',
            name: 'PL',
          },
        },
        deliveryTime: {
          '@type': 'ShippingDeliveryTime',
          handlingTime: {
            '@type': 'QuantitativeValue',
            minValue: 0,
            maxValue: 1,
            unitCode: 'DAY',
          },
          transitTime: {
            '@type': 'QuantitativeValue',
            minValue: 0,
            maxValue: deliveryTimeLabel.value,
            unitCode: 'DAY',
          },
        },
      },
      hasMerchantReturnPolicy,
    },
  };
};

export default simpleSchema;
