import { ProductSchemaParams } from '~/modules/jsonLD/composables/useJsonLd/useJsonLd';
import simpleSchema from '~/modules/jsonLD/schemes/product/product-types/simpleSchema';
import { AvailabilityEnum } from '~/modules/jsonLD/types';
import hasMerchantReturnPolicy from '~/modules/jsonLD/schemes/product/hasMerchantReturnPolicy';
import {
  cleanHtmlDescription, getChildAdditionalProperty, getVariantAdditionalProperty, getVariesBy,
} from '~/modules/jsonLD/helpers';
import { getProductThumbnailImage } from '~/modules/catalog/product/getters/productGetters';
import { useRoute } from '@nuxtjs/composition-api';
import { useImage } from '~/composables';

const configurableSchema = (params: ProductSchemaParams) => {
  const noImage = 'https://agrosimex.pl/icons/product-placeholder.svg';
  const route = useRoute();
  const { getMagentoImage } = useImage();
  const { product, currentProduct: childProduct, attributeValues } = params;
  const isChild = product.value?.sku !== childProduct.value?.sku;
  const baseSchema = simpleSchema(params);

  if (isChild) {
    return {
      ...baseSchema,
      sku: childProduct.value?.sku,
      additionalProperty: getChildAdditionalProperty(product, childProduct, attributeValues),
      isVariantOf: {
        '@type': 'ProductGroup',
        '@id': `#product-group-${product.value?.sku}`,
        productGroupID: product.value?.sku,
        name: product.value?.name,
        description: cleanHtmlDescription(product.value?.description?.html) || '',
        ...(product.value?.url_key && {
          url: `https://agrosimex.pl/${product.value?.url_key}`,
        }),
        variesBy: getVariesBy(product, attributeValues),
      },
    };
  }

  const { offers, sku, ...parentSchemaWithoutOffers } = baseSchema;

  return {
    ...parentSchemaWithoutOffers,
    '@type': 'ProductGroup',
    '@id': `#product-group-${product.value?.sku}`,
    productGroupID: product.value?.sku,
    variesBy: getVariesBy(product, attributeValues),
    hasVariant: product.value?.variants?.map(({ product: child, attributes }) => ({
      sku: child.sku,
      name: child.name,
      productGroupID: product.value?.sku,
      '@id': `#product-group-${product.value?.sku}`,
      description: cleanHtmlDescription(product.value?.description?.html) || '',
      image: getMagentoImage(getProductThumbnailImage(product.value)) || noImage,
      ...(child.ean && { gtin13: child.ean }),
      offers: {
        '@type': 'Offer',
        priceCurrency: 'PLN',
        price: child.price_range?.minimum_price?.final_price?.value || 0,
        availability: `http://schema.org/${AvailabilityEnum[child.stock_status]}`,
        url: `https://agrosimex.pl${route.value.path}?childSku=${child.sku}`,
        shippingDetails: {
          '@type': 'OfferShippingDetails',
          shippingRate: {
            '@type': 'MonetaryAmount',
            value: 0,
            currency: 'PLN',
          },
          shippingDestination: {
            '@type': 'DefinedRegion',
            addressCountry: {
              '@type': 'Country',
              name: 'PL',
            },
          },
        },
        hasMerchantReturnPolicy,
      },
      additionalProperty: getVariantAdditionalProperty(attributes, attributeValues),
      hasMerchantReturnPolicy,
    })),
  };
};

export default configurableSchema;
