import { ProductSchemaParams } from '~/modules/jsonLD/composables/useJsonLd/useJsonLd';
import { AvailabilityEnum } from '~/modules/jsonLD/types';
import simpleSchema from '~/modules/jsonLD/schemes/product/product-types/simpleSchema';
import { useImage } from '~/composables';

const bundleSchema = (params:ProductSchemaParams) => {
  const baseSchema = simpleSchema(params);
  const { bundleProductItems } = params;
  const noImage = 'https://agrosimex.pl/icons/product-placeholder.svg';
  const { getMagentoImage } = useImage();

  const getBundleProductItem = (item) => {
    const bundleProduct = item.options[0].product;
    return {
      '@type': 'Product',
      name: bundleProduct.name,
      sku: bundleProduct.sku,
      gtin13: bundleProduct.ean,
      availability: `http://schema.org/${AvailabilityEnum[bundleProduct.stock_status]}`,
      image: getMagentoImage(bundleProduct.thumbnail.url as string, {
        width: 400,
        height: 400,
      }) || noImage,
      url: `https://agrosimex.pl/${bundleProduct.url_key}`,
    };
  };

  const relatedToItems = bundleProductItems.value.map((i) => getBundleProductItem(i)) || [];
  return {
    ...baseSchema,
    ...(relatedToItems.length > 0 && { isRelatedTo: relatedToItems }),
  };
};

export default bundleSchema;
