export default {
  '@type': 'MerchantReturnPolicy',
  returnPolicyCategory: 'http://schema.org/MerchantReturnFiniteReturnWindow',
  merchantReturnDays: 14,
  returnMethod: 'http://schema.org/ReturnByMail',
  returnFees: 'http://schema.org/FreeReturn',
  applicableCountry: {
    '@type': 'Country',
    name: 'PL',
  },
};
