import { ProductOfferInterface } from '~/modules/jsonLD/types';
import { Aggregation, ConfigurableAttributeOption } from '~/modules/GraphQL/types';
import { getAttributeLabel } from '~/modules/catalog/product/getters/productGetters';
import { ComputedRef, Ref } from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';

export interface ProductPriceForSchemaOfferParams {
  sku: string;
  offer: ProductOfferInterface;
}

export const productPriceForSchemaOffer = ({ sku, offer }: ProductPriceForSchemaOfferParams): string | number => {
  if (sku.endsWith('-wait') && (offer.price === 0 || offer.price === '0')) {
    return 1;
  }
  return offer.price;
};

export const cleanHtmlDescription = (html: string, maxLength: number = 500): string => {
  if (!html) return '';
  const result = html
    .replace(/<[^>]*>/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();
  if (result.length > maxLength) {
    return `${result.slice(0, Math.max(0, maxLength))}...`;
  }
  return result;
};

export const getVariesByAttributeDescription = (label: string, attributeValues: Ref<Aggregation[]>) => {
  const attribute = attributeValues.value.find((attr) => attr.attribute_code === label);
  return attribute ? attribute.label : '';
};

export const getVariesBy = (product: Ref<Product>, attributeValues: Ref<Aggregation[]>) => {
  const variesBy = [];
  product.value.variants.forEach((variant) => {
    variant.attributes.forEach((attribute) => {
      if (!variesBy.find((el) => el.name === attribute.code)) {
        variesBy.push({
          '@type': 'DefinedTerm',
          name: attribute.code,
          description: getVariesByAttributeDescription(attribute.code, attributeValues),
        });
      }
    });
  });
  return variesBy;
};

export const getVariantAdditionalProperty = (attributes:ConfigurableAttributeOption[], attributeValues: Ref<Aggregation[]>) => {
  const additionalProperty = [];
  attributes.forEach((attr) => {
    additionalProperty.push({
      '@type': 'PropertyValue',
      name: attr.code,
      value: getAttributeLabel(attr.code, attr.value_index, attributeValues.value),
    });
  });
  return additionalProperty;
};

export const getChildAdditionalProperty = (product: Ref<Product>, childProduct:ComputedRef<Product>, attributeValues: Ref<Aggregation[]>) => {
  const selectedVariant = product.value.variants.find((variant) => variant.product.sku === childProduct.value.sku);
  return getVariantAdditionalProperty(selectedVariant.attributes, attributeValues);
};
