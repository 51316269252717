export enum AvailabilityEnum {
  IN_STOCK = 'InStock',
  PHONE_ORDER = 'PreOrder',
  WITHDRAWN = 'OutOfStock',
  OUT_OF_STOCK = 'OutOfStock',
}

export interface ProductOfferInterface {
  price: string | number;
  availability: keyof typeof AvailabilityEnum;
  url: string;
  ean: string | number;
}
