const organizationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://agrosimex.pl/',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+48 668 0914',
      email: 'sklep.online@agrosimex.com.pl',
    },
  ],
};

export default organizationSchema;
