import {
  ArticleSchemaParams,
  BreadcrumbsSchemaParams,
  ProductSchemaParams,
  UseJsonLdInterface,
} from '~/modules/jsonLD/composables/useJsonLd/useJsonLd';
import organizationSchema from '~/modules/jsonLD/schemes/organizationSchema';
import breadcrumbsSchema from '~/modules/jsonLD/schemes/breadcrumbsSchema';
import articleSchema from '~/modules/jsonLD/schemes/articleSchema';
import productSchema from '~/modules/jsonLD/schemes/product/productSchema';

export const useJsonLd = ():UseJsonLdInterface => {
  const getOrganizationSchema = () => JSON.stringify(organizationSchema);
  const getBreadcrumbsSchema = (params:BreadcrumbsSchemaParams) => JSON.stringify(breadcrumbsSchema(params));
  const getArticleSchema = (params:ArticleSchemaParams) => JSON.stringify(articleSchema(params));
  const getProductSchema = (params: ProductSchemaParams) => JSON.stringify(productSchema(params));

  return {
    getOrganizationSchema,
    getBreadcrumbsSchema,
    getArticleSchema,
    getProductSchema,
  };
};
