















































































import {
  computed, PropType, defineComponent, ref,
} from '@nuxtjs/composition-api';
import { useImage } from '~/composables';
import { DeviceSizes } from '~/composables/useImage/useImage';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import imagePlaceholder from '@storefront-ui/shared/images/product_placeholder.svg';

interface Srcset {
  width?: string;
  resolution?: string;
  breakpoint?: string;
  src: string;
}

export default defineComponent({
  name: 'ImageWrapper',
  props: {
    src: {
      type: String as PropType<string>,
      required: true,
    },
    alt: {
      type: String as PropType<string>,
      required: true,
    },
    placeholder: {
      type: String as PropType<string>,
      default: imagePlaceholder,
    },
    nuxtImgConfig: {
      type: Object as PropType<DeviceSizes | null>,
      default: () => null,
    },
    srcsets: {
      type: Array as PropType<Record<any, any>[]>,
      default: () => [],
      validator: (value: Record<any, any>) => value.every((item) => item.resolution && item.src) || value.every((item) => item.src && item.width),
    },
    width: {
      type: Number,
      default: null,
      validator: (value: Number) => !Number.isNaN(value),
    },
    height: {
      type: Number,
      default: null,
      validator: (value: Number) => !Number.isNaN(value),
    },
    loading: {
      type: String,
      default: 'lazy',
      validator: (value: string) => ['', 'lazy', 'eager'].includes(value),
    },
    imageTag: {
      type: String,
      default: 'img',
      validator: (value: string) => ['', 'img', 'nuxt-img'].includes(value),
    },
    fetchpriority: {
      type: String,
      default: 'auto',
      validator: (value: string) => ['high', 'low', 'auto'].includes(value),
    },
  },
  setup(props) {
    const { getMagentoImage } = useImage();

    const loaded = ref(false);

    const desktopUrl = computed(() => (props.nuxtImgConfig?.desktop?.width && props.nuxtImgConfig?.desktop?.height
      ? getMagentoImage(
        props.src,
        {
          width: props.nuxtImgConfig?.desktop?.width,
          height: props.nuxtImgConfig?.desktop?.height,
        },
      ) : null));
    const tabletUrl = computed(() => (props.nuxtImgConfig?.tablet?.width && props.nuxtImgConfig?.tablet?.height
      ? getMagentoImage(
        props.src,
        {
          width: props.nuxtImgConfig?.tablet?.width,
          height: props.nuxtImgConfig?.tablet?.height,
        },
      ) : null));
    const mobileUrl = computed(() => (props.nuxtImgConfig?.mobile?.width && props.nuxtImgConfig?.mobile?.height
      ? getMagentoImage(
        props.src,
        {
          width: props.nuxtImgConfig?.mobile?.width,
          height: props.nuxtImgConfig?.mobile?.height,
        },
      ) : null));
    const sortedSrcsets = computed(() => [...props.srcsets as Srcset[]].sort((setA, setB) => (setA.width && setB.width
      ? Number.parseInt(setA.width, 10) - Number.parseInt(setB.width, 10)
      : Number.parseInt(setA.resolution || '0', 10) - Number.parseInt(setB.resolution || '0', 10))));

    const formatResolution = (resolution?: string): string => {
      if (!resolution) return '';
      return resolution.endsWith('x') ? resolution : `${resolution}x`;
    };
    const srcsetDescriptor = (srcsetAtr: { width?: string; resolution?: string }) => (srcsetAtr.width ? `${Number.parseInt(srcsetAtr.width, 10) || ''}w` : formatResolution(srcsetAtr.resolution));
    const formatDimension = (size?: string): string => {
      if (!size) return '';
      return ['%', 'rem', 'em', 'px', 'vw', 'vh'].some((unit) => size.endsWith(unit)) || !Number.parseInt(size, 10)
        ? size
        : `${size}px`;
    };
    const formatBreakpoint = (breakpoint?: string): string => (breakpoint ? `(max-width: ${breakpoint}px) ` : '');
    const srcset = computed(() => {
      if (sortedSrcsets.value.length === 0) return null;
      return sortedSrcsets.value
        .map((set) => `${set.src} ${srcsetDescriptor(set)}`)
        .join(', ');
    });

    const sizes = computed(() => {
      const hasBreakpoints = sortedSrcsets.value.every((set) => set.breakpoint && set.width);
      if (!hasBreakpoints) return null;
      return sortedSrcsets.value
        .map((set) => `${formatBreakpoint(set.breakpoint)}${formatDimension(set.width)}`)
        .join(', ');
    });

    const classes = computed(() => (loaded.value ? 'image image-loaded' : 'image'));

    const imageComponentTag = computed(() => (props.imageTag || 'img'));

    const isPlaceholderVisible = computed(() => loaded.value || (!loaded.value && !props.placeholder));

    const attributes = computed(() => {
      const {
        desktop, tablet, mobile, ...nuxtImgConfig
      } = props.nuxtImgConfig || {};
      return props.imageTag === 'img' || props.imageTag === ''
        ? {
          sizes: sizes.value,
          srcset: srcset.value,
        }
        : {
          width: props.width || null,
          height: props.height || null,
          ...nuxtImgConfig,
        };
    });

    const styles = computed(() => {
      if (!props.width && !srcset.value && (props.imageTag === 'img' || props.imageTag === '')) {
        console.warn('Missing required prop width.');
      }
      if (!props.height && !srcset.value && (props.imageTag === 'img' || props.imageTag === '')) {
        console.warn('Missing required prop height.');
      }
      return {
        '--_image-width': props.width ? `${props.width}px` : null,
        '--_image-height': props.height ? `${props.height}px` : null,
      };
    });

    const onLoad = () => {
      loaded.value = true;
    };

    return {
      onLoad,
      classes,
      imageComponentTag,
      isPlaceholderVisible,
      styles,
      attributes,
      desktopUrl,
      tabletUrl,
      mobileUrl,
    };
  },
});
