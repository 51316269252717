import { ProductSchemaParams } from '~/modules/jsonLD/composables/useJsonLd/useJsonLd';
import { getTypeId } from '~/modules/catalog/product/getters/productGetters';
import { ProductTypeEnum } from '~/modules/catalog/product/enums/ProductTypeEnum';
import configurableSchema from '~/modules/jsonLD/schemes/product/product-types/configurableSchema';
import simpleSchema from '~/modules/jsonLD/schemes/product/product-types/simpleSchema';
import bundleSchema from '~/modules/jsonLD/schemes/product/product-types/bundleSchema';
import { computed } from '@nuxtjs/composition-api';

const productSchema = (params: ProductSchemaParams) => {
  if (!params.product.value) {
    return '';
  }
  const type = computed(() => getTypeId(params.product.value) as ProductTypeEnum);

  switch (type.value) {
    case ProductTypeEnum.VIRTUAL_PRODUCT:
    case ProductTypeEnum.SIMPLE_PRODUCT:
      return simpleSchema(params);
    case ProductTypeEnum.BUNDLE_PRODUCT:
      return bundleSchema(params);
    case ProductTypeEnum.CONFIGURABLE_PRODUCT:
      return configurableSchema(params);
    default:
      return '';
  }
};

export default productSchema;
