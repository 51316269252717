import { BreadcrumbsSchemaParams } from '~/modules/jsonLD/composables/useJsonLd/useJsonLd';

const breadcrumbsSchema = (breadcrumbs: BreadcrumbsSchemaParams) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbs.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: breadcrumb.text,
    item: `https://agrosimex.pl${breadcrumb.link}`,
  })),
});

export default breadcrumbsSchema;
